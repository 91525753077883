import React from 'react';
import LazyLoad from 'react-lazyload';
import Featured from './featured';

export default function LazyFeaturedCareers(props) {

    return(
        <LazyLoad>
            <Featured {...props} />
        </LazyLoad>
    )
}