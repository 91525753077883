import React, { Component } from 'react';
import careersAPI from '../../api/careersAPI';
import AbstractCareers from '../../careers/abstract';

import Career from '../career';
import Carousel from '../../ui/carousel';
import SliderArrow from "../../base/sliderArrow"

const SLIDER_SETTINGS = {
    dots: false,
    infinite: false,
    arrows: true,
    nextArrow: <SliderArrow direction="slider-arrow slider-arrow--next"/>,
    prevArrow: <SliderArrow direction="slider-arrow slider-arrow--prev"/>,
    variableWidth: false,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: true,
    className: 'arrows__slider',
    responsive: [

        {
            breakpoint: 1025,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                dots: false
            }
        },
        {
            breakpoint: 769,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                dots: false
            }
        },
        {
            breakpoint: 450,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false
            }
        }
    ]
};

class Featured extends AbstractCareers {

    state = {
        careers: [],
        profileCompleted: false,
        isLoading: true
    };

    componentDidMount() {
        careersAPI.featured(true, {random_order: true}).then( (result) => {
            console.log(result);
            let careers = result.career_scores.map((career) => {
                return {...career,...{score: (result.profile_completed ? career.score : Math.floor(Math.random() * 50 + 50))}}
            });
            this.setState({careers: careers, profileCompleted: result.profile_completed, isLoading: true});
        }).catch((error) => {
        console.log(error);
        });
    }

    render() {
        if (!this.state.isLoading || this.state.careers.length === 0) {
            return null
        }

        return (
            <Carousel items={this.state.careers} settings={SLIDER_SETTINGS}
                      itemProps={{hideScore: !this.state.profileCompleted, hideMatch: false}}
                      component={Career} />
        );
    }

}

export default Featured;