import {actions} from "react-fields";

export default class CareersAPI {

    static searchCareers(options) {
        let url = `/api/careers/search`;
        return fetch(actions.getData(`${url}?${actions.paramsToURI(options)}`))
            .then(actions.status)
            .then(actions.json)
    }

    static popularCareers(options) {
        let url = `/api/careers/popular`;
        return fetch(actions.getData(`${url}?${actions.paramsToURI(options)}`))
            .then(actions.status)
            .then(actions.json)
    }

    static similarCareers(options) {
        let url = `/api/careers/similar`;
        return fetch(actions.getData(`${url}?id=${options.id}`))
            .then(actions.status)
            .then(actions.json)
    }

    static getCollection() {
        let url = `/api/careers`;
        return fetch(actions.getData(url))
            .then(actions.status)
            .then(actions.json)
    }

    static featured(with_scores = false, options = {}) {
        let url = `/api/careers/featured`;
        if (with_scores) { options['with_scores'] = true }
        return fetch(actions.getData(`${url}?${actions.paramsToURI(options)}`))
            .then(actions.status)
            .then(actions.json)
    }

}